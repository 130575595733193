function handleMessages(messageId, dismissButtonId, localStorageKey) {
  const message = document.getElementById(messageId);
  const dismissButton = document.getElementById(dismissButtonId);

  if (message && dismissButton) {
    if (!localStorage.getItem(localStorageKey)) {
      message.style.display = 'block';
    }

    dismissButton.addEventListener('click', function() {
      message.style.display = 'none';
      localStorage.setItem(localStorageKey, 'true');
    });
  } else {
    console.info('Element not found:', message ? dismissButtonId : messageId);
  }
}

export { handleMessages };
